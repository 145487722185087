import React from "react";
import { Link } from "gatsby";

export default function SupportButtons({
  plannedGivingMessage,
  donationsMessage,
  scholarshipEndowmentMessage,
}) {
  return (
    <>
      <div
        className="width-container text-center"
        style={{
          marginTop: 40,
        }}
      >
        <SupportButtonContainer
          // message={donationsMessage}
          linkHref={"/donate/"}
          linkClassName={"btn primary-btn full-width-sm"}
          linkText={"DONATE NOW"}
        />
      </div>
      <div className="padding-top-2 text-center">
        <div className="width-container text-center">
          <div className="grid-container btn-group add-width">
            <div className="grid-item grid-item-2">
              <SupportButtonContainer
                // message={plannedGivingMessage}
                linkHref={"/planned-giving"}
                linkClassName={"btn primary-btn btn-1"}
                linkText={"PLANNED GIVING"}
              />
            </div>
            <div className="grid-item grid-item-2">
              <SupportButtonContainer
                // message={scholarshipEndowmentMessage}
                linkHref={"/scholarship-endowment/"}
                linkClassName={"btn primary-btn btn-2"}
                linkText={"SCHOLARSHIP ENDOWMENT"}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function SupportButtonContainer({
  message,
  linkText,
  linkHref,
  linkClassName,
}) {
  return (
    <div style={{ background: "#eeeeee", padding: 16, borderRadius: 8 }}>
      {message && <p>{message}</p>}
      <Link to={linkHref} className={linkClassName}>
        {linkText}
      </Link>
    </div>
  );
}
