import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import Layout from "../components/Layout";
import PageBanner from "../components/PageBanner";
import Icon from "../components/Icon";
import YouTubeVideo from "../components/YouTubeVideo";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import SupportButtons from "../components/SupportButtons";

const helpIcons = {
  Podium: <Icon iconName="podium" width={55} />,
  Tours: (
    <span>
      <Icon iconName="hikingLight" width={27} />
      <Icon iconName="hikingLight" width={27} />
      <Icon iconName="hiking" width={27} />
    </span>
  ),
  Shadowing: <Icon iconName="carrying" width={60} />,
  Volunteer: <Icon iconName="heartHands" width={60} />,
  Employment: (
    <div style={{ transform: "rotate(-30deg)" }}>
      <Icon iconName="handshakeAlt" width={60} />
    </div>
  ),
  Donate: <Icon iconName="badgeDollar" width={60} />,
};

export const SupportPageTemplate = (props) => {
  const {
    banner,
    bodyImage,
    helpItems,
    supportersMessage,
    donationsMessage,
    plannedGivingMessage,
    scholarshipEndowmentMessage,
  } = props;
  return (
    <>
      <PageBanner>
        <h1 className="page-banner-header">SUPPORT US</h1>
      </PageBanner>
      <div className="layout-container">
        <div className="grid-container top-image-container alt-banner center-md-left-sm">
          <div className="grid-item-2 grid-item">
            <div className="width-container">
              <h4>{banner.heading}</h4>
              <p>{banner.description}</p>
              <a href="#how-to-help" className="btn primary-btn full-width-sm">
                LEARN MORE
              </a>
            </div>
          </div>
          <div className="grid-item-2 grid-item">
            <YouTubeVideo src={banner.video} height={340} width={"100%"} />
          </div>
        </div>
        <section className="body-id-section" id="how-to-help">
          <div className="landing-header-container text-center">
            <h3>How You Can Help</h3>
          </div>
          <div className="grid-container">
            {helpItems.map(({ icon, heading, description }, index) => {
              return (
                <div
                  className="grid-item grid-item-3 support-item"
                  key={`support-item-${index}`}
                >
                  <div className="support-icon">{helpIcons[icon]}</div>
                  <h5>{heading}</h5>
                  <p>{description}</p>
                </div>
              );
            })}
          </div>
          <SupportButtons
            donationsMessage={donationsMessage}
            plannedGivingMessage={plannedGivingMessage}
            scholarshipEndowmentMessage={scholarshipEndowmentMessage}
          />
          <div className="body-bg-image support-body-img body-img">
            <PreviewCompatibleImage imageData={bodyImage} />
          </div>
        </section>
        <section className="body-id-section">
          <div className="width-container text-center">
            <p>{supportersMessage}</p>
            <div className="bottom-btn-container text-center">
              <Link to="/supporters/" className="btn primary-btn full-width-sm">
                VIEW SCHOOL SUPPORTERS
              </Link>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

const SupportPage = ({ data }) => {
  const {
    markdownRemark: {
      frontmatter: {
        banner,
        bodyImage,
        helpItems,
        supportersMessage,
        donationsMessage,
        plannedGivingMessage,
        scholarshipEndowmentMessage,
      },
    },
  } = data;
  return (
    <Layout
      pageTitle={`Support Us`}
      ogUrl="/support/"
      pageDecription="Discover the ways you can help Philips Academy continue to thrive as a non-profit special education school."
    >
      <SupportPageTemplate
        banner={banner}
        bodyImage={bodyImage}
        helpItems={helpItems}
        supportersMessage={supportersMessage}
        donationsMessage={donationsMessage}
        plannedGivingMessage={plannedGivingMessage}
        scholarshipEndowmentMessage={scholarshipEndowmentMessage}
      />
    </Layout>
  );
};

SupportPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default SupportPage;

export const SupportPageQuery = graphql`
  query SupportPageQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        banner {
          heading
          description
          video
        }
        helpItems {
          icon
          heading
          description
        }
        bodyImage {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        employmentSection {
          legalDisclaimer
          image {
            childImageSharp {
              fluid(maxWidth: 900, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          jobPostings {
            title
            description
            pdf {
              publicURL
            }
          }
        }
        donationsMessage
        supportersMessage
        plannedGivingMessage
        scholarshipEndowmentMessage
      }
    }
  }
`;
